/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { 
  RiArrowLeftSLine, 
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiStarFill,
  RiStarLine,
  RiInformationLine,
  RiPriceTag3Line,
  RiUserSmileLine,
  RiComputerLine,
  RiCustomerService2Line
} from "react-icons/ri"

const ATSPage = () => {
  // ATS comparison data
  const atsComparison = [
    {
      name: "Greenhouse",
      website: "https://www.greenhouse.io",
      priceRange: "$$$",
      pricingModel: "Per employee/month",
      userRating: 4.5,
      userReviews: 1200,
      usability: 4.7,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Medium to Enterprise",
      keyFeatures: [
        "Structured interview kits",
        "Advanced DEI analytics",
        "700+ job board integrations",
        "Custom workflows"
      ],
      limitations: [
        "Higher price point",
        "Complex initial setup",
        "Enterprise focus"
      ],
      bestFor: "Large companies with complex hiring needs"
    },
    {
      name: "Lever",
      website: "https://www.lever.co",
      priceRange: "$$$",
      pricingModel: "Per employee/month",
      userRating: 4.4,
      userReviews: 950,
      usability: 4.6,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "SMB to Enterprise",
      keyFeatures: [
        "Visual pipeline management",
        "Two-way email sync",
        "Advanced reporting",
        "Native CRM features"
      ],
      limitations: [
        "Limited customization for smaller plans",
        "More expensive than basic solutions",
        "Some reports require professional services"
      ],
      bestFor: "Fast-growing tech companies needing a scalable solution"
    },
    {
      name: "Workday Recruiting",
      website: "https://www.workday.com/recruiting",
      priceRange: "$$$$",
      pricingModel: "Custom enterprise pricing",
      userRating: 4.1,
      userReviews: 1100,
      usability: 3.9,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Enterprise",
      keyFeatures: [
        "Full HCM integration",
        "Detailed analytics",
        "Powerful talent management",
        "Compliance-focused"
      ],
      limitations: [
        "Complex implementation",
        "Steep learning curve",
        "Enterprise pricing only"
      ],
      bestFor: "Large enterprises needing full HR suite integration"
    },
    {
      name: "SmartRecruiters",
      website: "https://www.smartrecruiters.com",
      priceRange: "$$$",
      pricingModel: "Per hire + subscription",
      userRating: 4.3,
      userReviews: 850,
      usability: 4.4,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Medium to Enterprise",
      keyFeatures: [
        "AI-powered screening",
        "Global compliance",
        "Extensive marketplace",
        "Talent CRM"
      ],
      limitations: [
        "Higher-end pricing",
        "Some features require add-ons",
        "Complex for small businesses"
      ],
      bestFor: "Companies with global hiring needs"
    },
    {
      name: "iCIMS",
      website: "https://www.icims.com",
      priceRange: "$$$",
      pricingModel: "Per module/custom pricing",
      userRating: 4.2,
      userReviews: 1050,
      usability: 4.0,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Medium to Enterprise",
      keyFeatures: [
        "Modular approach",
        "Extensive customization",
        "Comprehensive career site builder",
        "Strong compliance tools"
      ],
      limitations: [
        "Can be expensive with all modules",
        "Implementation complexity",
        "UI not as modern as newer options"
      ],
      bestFor: "Companies needing extensive customization"
    },
    {
      name: "JazzHR",
      website: "https://www.jazzhr.com",
      priceRange: "$$",
      pricingModel: "Monthly subscription tiers",
      userRating: 4.3,
      userReviews: 750,
      usability: 4.5,
      mobileFriendly: true,
      aiFeatures: false,
      companySize: "Small to Medium",
      keyFeatures: [
        "Affordable pricing",
        "User-friendly interface",
        "Good integration options",
        "Customizable workflows"
      ],
      limitations: [
        "Limited advanced features",
        "Fewer enterprise capabilities",
        "Basic reporting"
      ],
      bestFor: "Small to medium businesses with straightforward hiring needs"
    },
    {
      name: "BambooHR ATS",
      website: "https://appwiki.nl/link/brand/1OIOrEAw4ngBccSnuIoaAmJ9r5VK5anS",
      priceRange: "$$",
      pricingModel: "Per employee/month",
      userRating: 4.5,
      userReviews: 1300,
      usability: 4.8,
      mobileFriendly: true,
      aiFeatures: false,
      companySize: "Small to Medium",
      keyFeatures: [
        "Excellent UX/UI",
        "Full HRIS integration",
        "Mobile app",
        "Employee self-service"
      ],
      limitations: [
        "Limited advanced recruiting features",
        "Better for smaller teams",
        "Some customization limitations"
      ],
      bestFor: "SMBs wanting an easy-to-use ATS that integrates with HRIS"
    },
    {
      name: "Zoho Recruit",
      website: "https://www.zoho.com/recruit",
      priceRange: "$",
      pricingModel: "Per user/month",
      userRating: 4.2,
      userReviews: 820,
      usability: 4.3,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Small to Medium",
      keyFeatures: [
        "Affordable pricing",
        "Strong Zoho ecosystem",
        "Multi-channel sourcing",
        "Customizable workflows"
      ],
      limitations: [
        "Interface can be complex",
        "Limited enterprise features",
        "Support can be inconsistent"
      ],
      bestFor: "Budget-conscious companies already using Zoho products"
    },
    {
      name: "Jobvite",
      website: "https://www.jobvite.com",
      priceRange: "$$$",
      pricingModel: "Per module/custom pricing",
      userRating: 4.0,
      userReviews: 780,
      usability: 4.1,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Medium to Enterprise",
      keyFeatures: [
        "Strong social recruiting",
        "Comprehensive campaign tools",
        "Advanced analytics",
        "Extensive integrations"
      ],
      limitations: [
        "Higher price point",
        "Can feel dated compared to newer options",
        "Implementation complexity"
      ],
      bestFor: "Companies focused on employer branding and social recruiting"
    },
    {
      name: "Breezy HR",
      website: "https://breezy.hr",
      priceRange: "$",
      pricingModel: "Monthly subscription tiers",
      userRating: 4.4,
      userReviews: 650,
      usability: 4.6,
      mobileFriendly: true,
      aiFeatures: true,
      companySize: "Small to Medium",
      keyFeatures: [
        "Visual candidate pipelines",
        "Video interviews",
        "Affordable pricing",
        "Easy setup"
      ],
      limitations: [
        "Limited advanced features",
        "Basic reporting",
        "Not ideal for enterprise"
      ],
      bestFor: "Small businesses and startups needing user-friendly ATS"
    }
  ];

  // Helper function to render star ratings
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.3;
    
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary' }} />);
      } else if (i === fullStars + 1 && hasHalfStar) {
        stars.push(<RiStarFill key={i} sx={{ color: 'primary', opacity: 0.5 }} />);
      } else {
        stars.push(<RiStarLine key={i} sx={{ color: 'primary' }} />);
      }
    }
    
    return <div sx={{ display: 'flex', alignItems: 'center' }}>{stars} <span sx={{ ml: 1 }}>({rating}/5)</span></div>;
  };

  return (
    <Layout>
      <SEO 
        title="Top 10 Applicant Tracking Systems (ATS) Compared | HR Software Guide"
        description="Compare the top 10 Applicant Tracking Systems (ATS) for 2025. Detailed analysis of pricing, usability, features, and user feedback to help you choose the best ATS."
        keywords={[
          "applicant tracking system comparison",
          "best ATS software",
          "Greenhouse vs Lever",
          "ATS pricing comparison",
          "ATS user reviews",
          "small business ATS",
          "enterprise ATS solutions"
        ]}
      />
      
      <div sx={{
        bg: 'primaryMuted',
        pt: [4, 5],
        pb: [4, 5]
      }}>
        <div sx={{
          maxWidth: '1200px',
          mx: 'auto',
          px: 3
        }}>
          <div sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 4
          }}>
            <Link to="/software" sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'primary',
              textDecoration: 'none',
              fontSize: '1rem',
              fontWeight: 500
            }}>
              <RiArrowLeftSLine sx={{ mr: 1 }} /> Back to HR Software
            </Link>
          </div>
          
          <div sx={{
            textAlign: 'center',
            mb: 2,
            fontSize: '0.95rem',
            color: 'text',
            opacity: 0.8
          }}>
            Last Updated: March 2025
          </div>
          
          <h1 sx={{
            fontSize: ['1.8rem', '2.3rem'],
            fontWeight: 700,
            textAlign: 'center',
            mb: 3,
            color: 'primary'
          }}>
            Top 10 Applicant Tracking Systems Compared
          </h1>
          
          <p sx={{
            fontSize: '1.1rem',
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 5,
            lineHeight: 1.5,
            color: 'text'
          }}>
            An in-depth comparison of the leading ATS solutions to help you find the right recruiting software
            for your organization's hiring needs, budget, and team size.
          </p>
          
          {/* Comparison criteria explanation */}
          <div sx={{
            bg: 'white',
            p: 3,
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{
              fontSize: '1.3rem',
              fontWeight: 600,
              mb: 3,
              display: 'flex',
              alignItems: 'center'
            }}>
              <RiInformationLine sx={{ mr: 2, color: 'primary' }} /> Comparison Criteria
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr 1fr'],
              gap: 3,
              mb: 3
            }}>
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiPriceTag3Line sx={{ mr: 1, color: 'primary' }} /> Pricing
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  $ = Budget (Under $500/mo)<br />
                  $$ = Mid-range ($500-1,500/mo)<br />
                  $$$ = Premium ($1,500-3,000/mo)<br />
                  $$$$ = Enterprise (Custom)
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiUserSmileLine sx={{ mr: 1, color: 'primary' }} /> User Rating
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Based on verified reviews across G2, Capterra, and GetApp platforms. Rating out of 5.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiComputerLine sx={{ mr: 1, color: 'primary' }} /> Usability
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  Ease of use rating based on user feedback, UI design, and learning curve assessment.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1rem', fontWeight: 600, mb: 1, display: 'flex', alignItems: 'center' }}>
                  <RiCustomerService2Line sx={{ mr: 1, color: 'primary' }} /> Company Size
                </h3>
                <p sx={{ fontSize: '0.9rem', opacity: 0.8 }}>
                  The ideal company size range where the solution performs best based on features and pricing.
                </p>
              </div>
            </div>
          </div>
          
          {/* ATS comparison cards */}
          <div sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
            gap: 4,
            mb: 5
          }}>
            {atsComparison.map((ats, index) => (
              <div key={ats.name} sx={{
                bg: 'white',
                p: [3, 4],
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                display: 'flex',
                flexDirection: 'column',
                '&:hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
                }
              }}>
                <div sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: 3
                }}>
                  <h3 sx={{
                    fontSize: '1.4rem',
                    fontWeight: 700,
                    color: 'primary'
                  }}>
                    {index + 1}. {ats.name}
                  </h3>
                  <div sx={{
                    fontSize: '1.1rem',
                    fontWeight: 600,
                    color: 'primary'
                  }}>
                    {ats.priceRange}
                  </div>
                </div>
                
                <div sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr'],
                  gap: 3,
                  mb: 3
                }}>
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Pricing Model</div>
                    <div sx={{ fontSize: '0.9rem' }}>{ats.pricingModel}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Company Size</div>
                    <div sx={{ fontSize: '0.9rem' }}>{ats.companySize}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>User Rating ({ats.userReviews} reviews)</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(ats.userRating)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Usability</div>
                    <div sx={{ fontSize: '0.9rem' }}>{renderStars(ats.usability)}</div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Mobile-Friendly</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {ats.mobileFriendly ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                  
                  <div>
                    <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>AI Features</div>
                    <div sx={{ fontSize: '0.9rem' }}>
                      {ats.aiFeatures ? 
                        <span sx={{ color: 'green', display: 'flex', alignItems: 'center' }}>
                          <RiCheckboxCircleLine sx={{ mr: 1 }} /> Yes
                        </span> : 
                        <span sx={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                          <RiCloseCircleLine sx={{ mr: 1 }} /> No
                        </span>
                      }
                    </div>
                  </div>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Key Features</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {ats.keyFeatures.map(feature => (
                      <li key={feature} sx={{ fontSize: '0.9rem', mb: 1 }}>{feature}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{ mb: 3 }}>
                  <div sx={{ fontSize: '0.9rem', fontWeight: 600, mb: 1 }}>Limitations</div>
                  <ul sx={{ pl: 3, mb: 0 }}>
                    {ats.limitations.map(limitation => (
                      <li key={limitation} sx={{ fontSize: '0.9rem', mb: 1 }}>{limitation}</li>
                    ))}
                  </ul>
                </div>
                
                <div sx={{
                  bg: 'primaryMuted',
                  p: 2,
                  borderRadius: '4px',
                  textAlign: 'center',
                  fontWeight: 600,
                  mb: 3
                }}>
                  Best For: {ats.bestFor}
                </div>
                
                <div sx={{ mt: 'auto' }}>
                  <a href={ats.website} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    sx={{
                      display: 'block',
                      textAlign: 'center',
                      bg: 'primary',
                      color: 'white',
                      py: 2,
                      px: 3,
                      borderRadius: '4px',
                      textDecoration: 'none',
                      fontWeight: 600,
                      fontSize: '0.95rem',
                      transition: 'transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease',
                      '&:hover': {
                        bg: 'primaryDark',
                        color: 'white',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
                      }
                    }}
                  >
                    Visit Website
                  </a>
                </div>
              </div>
            ))}
          </div>
          
          {/* ATS selection guide */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            mb: 5
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              How to Select the Right ATS for Your Organization
            </h2>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Assess Your Recruiting Needs</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Before evaluating ATS options, identify your specific recruiting challenges and goals. Consider your hiring volume, 
                complexity of roles, geographic distribution, and compliance requirements. An enterprise hiring thousands 
                globally needs more sophisticated features than a small business hiring locally.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Consider Integration Requirements</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Evaluate how the ATS will integrate with your existing HR tech stack. Look for systems that connect with your 
                HRIS, background check providers, assessment tools, and other critical HR systems. Seamless integration reduces 
                manual data entry and improves overall efficiency.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Evaluate Candidate Experience</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                The best ATS solutions provide an excellent candidate experience. Test the application process yourself to 
                evaluate mobile responsiveness, application length, and overall user-friendliness. Remember that a poor application 
                experience can cause top candidates to abandon their applications.
              </p>
            </div>
            
            <div sx={{ mb: 4 }}>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Consider Total Cost of Ownership</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Look beyond the subscription price to understand implementation costs, add-on fees, and potential customization 
                expenses. Also consider indirect costs like training, ongoing administration, and potential productivity impacts 
                during implementation. The right solution balances cost with capabilities that meet your specific needs.
              </p>
            </div>
            
            <div>
              <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Request Demonstrations and Free Trials</h3>
              <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                Before making a final decision, arrange demonstrations with your top choices and involve key stakeholders 
                in the evaluation process. If possible, request a free trial to test the system with real hiring scenarios. 
                This hands-on experience will reveal how well the ATS aligns with your specific workflow and requirements.
              </p>
            </div>
          </div>
          
          {/* Trends in ATS technology */}
          <div sx={{
            bg: 'white',
            p: [3, 4],
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
          }}>
            <h2 sx={{ 
              fontSize: '1.4rem',
              fontWeight: 600,
              mb: 3,
              pb: 2,
              borderBottom: '1px solid',
              borderColor: 'primaryMuted'
            }}>
              Current Trends in ATS Technology
            </h2>
            
            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4,
              mb: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>AI-Powered Candidate Matching</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Advanced ATS platforms now use artificial intelligence to better match candidates to job requirements, 
                  reducing time-to-hire and improving quality of hire. These systems analyze resumes more intelligently 
                  and can identify candidates who might be overlooked by traditional keyword matching. Some platforms are 
                  now using natural language processing to understand the semantic meaning of skills and experiences, not just 
                  exact keyword matches.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Enhanced DEI Features</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Leading ATS solutions now include features to support diversity, equity, and inclusion initiatives. 
                  These include bias-reducing language tools, anonymous resume screening, diversity analytics, and 
                  specialized sourcing capabilities to reach underrepresented candidate pools. Recent advances include 
                  AI tools that analyze job descriptions for potentially biased language and suggest more inclusive 
                  alternatives, along with automated diversity reporting for EEOC compliance.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Mobile-First Design</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  With more candidates applying via mobile devices, ATS providers have prioritized mobile-responsive 
                  application processes. This includes one-click apply options, mobile-friendly assessments, and 
                  text-message based communication flows to engage candidates where they are. The latest innovations 
                  include voice-activated application options, video cover letters recorded on mobile devices, and 
                  integration with digital wallets to streamline document submission for background checks.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Comprehensive Analytics</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Modern ATS platforms offer powerful analytics capabilities to track key recruiting metrics, 
                  identify bottlenecks, and demonstrate ROI. These insights help recruiting teams continuously 
                  optimize their processes and make data-driven decisions about their talent acquisition strategies. 
                  Advanced systems now provide predictive analytics to forecast hiring needs, time-to-fill for specific 
                  roles, and even candidate success likelihood based on historical data patterns.
                </p>
              </div>
            </div>

            <div sx={{
              display: 'grid',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              gap: 4
            }}>
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Automation and Workflow Optimization</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Automation is transforming ATS capabilities, with systems now handling routine tasks like interview scheduling, 
                  candidate communications, and status updates without human intervention. Modern ATS platforms can trigger 
                  personalized email sequences based on candidate actions, schedule interviews by finding mutual availability in 
                  calendars, and even conduct initial screening through chatbots. This automation reduces time-to-hire by up to 
                  40% while allowing recruiters to focus on high-value candidate interactions.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Video Interview Integration</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  The widespread adoption of remote work has accelerated video interview integration within ATS platforms. 
                  Beyond simple video conferencing, advanced systems now offer asynchronous video interviewing where candidates 
                  can record responses to preset questions at their convenience. Some platforms include AI-driven analysis of 
                  recorded interviews that can evaluate candidate communication skills, engagement level, and even sentiment through 
                  facial expression analysis, though these features raise important ethical considerations.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Candidate Relationship Management</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  ATS platforms are increasingly incorporating robust CRM features to nurture talent pools and build long-term 
                  relationships with candidates. These tools help maintain engagement with passive candidates through personalized 
                  content, targeted communications, and event invitations. Advanced systems now use AI to suggest when to re-engage 
                  previous applicants based on new skills they may have acquired, changes in market conditions, or new positions that 
                  better match their profile than those they previously applied for.
                </p>
              </div>
              
              <div>
                <h3 sx={{ fontSize: '1.1rem', fontWeight: 600, mb: 2 }}>Integration Ecosystems</h3>
                <p sx={{ fontSize: '0.95rem', lineHeight: 1.7 }}>
                  Modern ATS solutions have evolved beyond standalone applications into platforms with robust integration ecosystems. 
                  Through APIs and partnerships, leading systems now integrate seamlessly with background check providers, skills assessment 
                  tools, HRIS platforms, and even employee onboarding systems. This integration creates an end-to-end talent acquisition 
                  and management environment where data flows automatically between systems, eliminating manual transfers and reducing 
                  the risk of errors while providing a unified experience for both candidates and hiring teams.
                </p>
              </div>
            </div>
          </div>
          
          {/* CTA for other HR software comparisons */}
          <div sx={{ mb: 5 }}>
            <h2 sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 700, mb: 4, textAlign: 'center' }}>
              Explore More HR Software Comparison Guides
            </h2>
            
            <div sx={{ 
              display: 'grid', 
              gridTemplateColumns: ['1fr', null, '1fr 1fr', 'repeat(4, 1fr)'],
              gap: 3,
              maxWidth: '900px',
              mx: 'auto'
            }}>
              <Link to="/software/hris" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  HRIS Systems
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Compare top HR information systems
                </div>
              </Link>
              
              <Link to="/software/performance-management" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  Performance Tools
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Review performance management platforms
                </div>
              </Link>
              
              <Link to="/software/learning-management-system" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  LMS Platforms
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Compare learning management systems
                </div>
              </Link>
              
              <Link to="/software/employee-engagement" sx={{
                display: 'block',
                bg: 'white',
                p: 3,
                borderRadius: '10px',
                boxShadow: '0 3px 10px rgba(0,0,0,0.08)',
                textDecoration: 'none',
                color: 'text',
                textAlign: 'center',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 8px 20px rgba(0,0,0,0.12)'
                }
              }}>
                <div sx={{ 
                  fontSize: '1.1rem', 
                  fontWeight: 600,
                  color: 'primary',
                  mb: 1
                }}>
                  Engagement Platforms
                </div>
                <div sx={{ fontSize: '0.9rem' }}>
                  Discover employee engagement solutions
                </div>
              </Link>
            </div>
          </div>
          
          {/* Get Expert Help CTA */}
          <div sx={{
            bg: 'primary',
            borderRadius: '12px',
            p: 4,
            textAlign: 'center',
            maxWidth: '800px',
            mx: 'auto',
            mb: 6
          }}>
            <h2 sx={{ 
              fontSize: ['1.5rem', '1.8rem'], 
              fontWeight: 700, 
              color: 'white',
              mb: 3 
            }}>
              Need Help Selecting the Right Applicant Tracking System?
            </h2>
            <p sx={{ 
              fontSize: ['1rem', '1.1rem'], 
              color: 'white',
              mb: 4,
              opacity: 0.9
            }}>
              Our HR technology experts can provide personalized recommendations based on your organization's 
              hiring volume, recruitment processes, and integration requirements.
            </p>
            <div sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              justifyContent: 'center',
              gap: 3
            }}>
              <Link to="/contact" sx={{
                bg: 'white',
                color: 'primary',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                transition: 'transform 0.2s, box-shadow 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  color: 'primary'
                }
              }}>
                Schedule a Free Consultation
              </Link>
              <Link to="/services/hr-software-selection" sx={{
                bg: 'transparent',
                color: 'white',
                py: 2,
                px: 4,
                borderRadius: '40px',
                fontWeight: 600,
                textDecoration: 'none',
                fontSize: '1rem',
                display: 'inline-block',
                border: '2px solid white',
                transition: 'transform 0.2s, box-shadow 0.2s, background-color 0.2s',
                ':hover': {
                  transform: 'translateY(-3px)',
                  boxShadow: '0 6px 20px rgba(0,0,0,0.2)',
                  bg: 'primaryDark',
                  color: 'white'
                }
              }}>
                Learn About Our Selection Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ATSPage; 